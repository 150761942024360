import React from "react"
import Joi from "joi-browser"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/common/form"
import { resetPassword } from "../services/authService"
import SpinnerLoading from "../components/common/spinnerLoading"
import { toast } from "react-toastify"
import queryString from "query-string"

class ResetPassword extends Form {
  state = {
    data: { password: "", password_confirmation: "" },
    errors: {},
    isLoggedIn: false,
    loading: false,
    params: { email: "", token: "" },
    passwordChanged: false,
  }

  componentDidMount() {
    let params = queryString.parse(this.props.location.search)
    this.setState({ params })
  }

  schema = {
    password: Joi.string()
      .required()
      .label("Password"),
    password_confirmation: Joi.string()
      .required()
      .label("Conferma Password"),
  }

  doSubmit = async e => {
    e.preventDefault()
    let errors = this.validate()
    this.setState({ errors: errors || {} })
    if (errors) {
      toast.error("Sono stati riscontrati degli errori di compilazione")
      return
    }
    if (errors === null) {
      errors = {}
    }
    const { data, params } = this.state
    if (data.password !== data.password_confirmation) {
      errors.password = "Le due password non coincidono"
      this.setState({ errors: errors || {} })
      return
    }

    data.email = params.email
    data.token = params.token

    this.setState({ loading: true })
    if (await resetPassword(data)) {
      this.setState({ passwordChanged: true, loading: false })
    } else {
      this.setState({ loading: false })
    }
  }

  render() {
    const { loading, passwordChanged } = this.state
    return (
      <Layout>
        <SEO
          title="Reset password"
          keywords={[`vision care`]}
          description="Reset password"
        />
        {loading && (
          <div className="mt-5 pt-5">
            <SpinnerLoading />
          </div>
        )}
        {!loading && (
          <div className={"container"}>
            <div className="row">
              <div className="col-12">
                <h1 className={"page-title"}>Modifica password</h1>

                <div className={"row"}>
                  {passwordChanged && (
                    <div className={"col-12 col-md-6"}>
                      Password modificata con successo.
                      <a href="/login">
                        <span className="text-orange">Torna al login</span>
                      </a>
                    </div>
                  )}
                  {!passwordChanged && (
                    <div className={"col-12 col-md-6"}>
                      <form onSubmit={this.handleSubmit} className="pb-5">
                        <div className="form-group">
                          {this.renderPassword(
                            "password",
                            "Inserisci la nuova password",
                            "text",
                            false,
                            "bootstrap"
                          )}
                          {this.renderPassword(
                            "password_confirmation",
                            "Conferma la nuova password",
                            "text",
                            false,
                            "bootstrap"
                          )}
                        </div>

                        <div className="form-group">
                          {this.renderButton("Salva", true)}
                        </div>

                        <ul>
                          <li className="my-0">
                            <a href="/login">
                              <span className="text-orange">
                                Torna al login
                              </span>
                            </a>
                          </li>
                        </ul>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    )
  }
}

export default ResetPassword
